import {Swiper , SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules";
import Stars from './Stars';

export default function SwiperReview(){

    const Data = [
        {
            id: 1,
            name: 'Mariam',
            stars: 5,
            desc: "I have been coming to this place for a year now, and every one who works here is kind/ patient and respectful. I broke my nails next day of getting them done, I came back and Tellie fixed them for me, with warming heart and didn't charge anything. She's amazing with her work."
        },
        {
            id: 2,
            name: 'Erica Ramirez',
            stars: 5,
            desc: "I made an appointment in the morning, and when I arrived I was greeted with kindness, and was taken care of right away. One of the best pedicures and manicures I have received. I will be going back for sure. Great service!"
        },
        {
            id: 3,
            name: 'Jaxon Naperala',
            stars: 5,
            desc: "I came into the salon for my nails and pedicure. The salon was decorated so nicely for the New Year! Look at this set up! I have been coming for a few years! Absolutely love every single employee, they all do amazing nails!"
        },
        {
            id: 4,
            name: 'Arianna Lyons',
            stars: 5,
            desc: "one star turned to five. i went in and got my nails done and i was not happy. the owner reached out and told me to come back and get them redone. they really do care about their customers! andy does the best work please ask for him! i wish i was able to add a photo for the review they look so cute!"
        },
        {
            id: 5,
            name: 'Jazlynn B',
            stars: 5,
            desc: "I got full set, pink and sparkles with glitter, they did such a nice job!! This experience was pain free and the service was fantastic!!!! I have always gone here and they always nail it."
        }
    ]

    return(
        <Swiper
            loop = {true}
            breakpoints={{
                340: {
                    slidesPerView:1,
                },
                700: {
                    slidesPerView: 1,
                }
            }}
            autoplay = {{
                delay: 4000,
                disableOnInteraction: false,
            }}
            modules={[Autoplay]}
        >
            {Data.map((s) => (
                <SwiperSlide>
                    <div className="p-2 text-black font-txt-font cursor-pointer">
                        <div className=" flex space-y-4 flex-col items-center justify-center text-center">
                            <div className=" text-2xl text-black/60">
                                <span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#808080"
                                        className="inline-block h-12 w-12 pr-2"
                                        viewBox="0 0 24 24">
                                        <path
                                            d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" />
                                    </svg>
                                    {s.desc}
                                </span>
                            </div>
                            <div>
                                <Stars len = {s.stars} />
                            </div>
                            <div className=" flex flex-col">
                                <h1 className=" font-extrabold text-xl">{s.name}</h1>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>   
            ))}
        </Swiper>
    );
};