import './App.css';
import Navbar from './Components/Navbar';
import Home from './Views/Home';
import Services from './Views/Services';
import { Route, Routes, Navigate} from 'react-router-dom';
import Footer from './Components/Footer';
import {Fade} from "react-awesome-reveal";

function App() {
  return (
    <main>
      <Navbar />
      <Fade delay={100}>
        <Routes>
          <Route path = "/" element = {<Home />} />
          <Route path = "/Services" element = {<Services />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Fade>
      <Footer />
    </main>
  );
}

export default App;
