import React from "react";
import ServicesData from "../Components/ServicesData";

import img1 from "../img/8.png";
import img2 from "../img/10.png";
import imgser3 from "../img/16.png";
import imgser4 from "../img/17.png";
import img3 from "../img/1.png";
import img4 from "../img/2.png";
import ser4 from "../img/ser4.png";
import ser5 from "../img/ser5.png";
import serimg from "../img/ser1.png";
import SwiperReview from "../Components/SwiperReviews";

import {Fade , Slide , JackInTheBox} from "react-awesome-reveal";


export default function Home(){
    return (
        <section className="relative overflow-x-hidden">
            <div className="w-screen bg-pagel bg-no-repeat bg-center bg-cover h-screen relative">
                <div className="w-screen absolute bg-black bg-opacity-50 flex justify-center items-center flex-col h-screen text-center px-3 py-5 space-y-5 lg:py-8 md:space-y-12">
                    <div className=" space-y-4 lg:space-y-8">
                        <h1 className="text-3xl capitalize text-white md:text-7xl tracking-wide" style={{ fontFamily: "Redressed" }}>
                            Welcome To
                        </h1>
                        <div className=" flex flex-col">
                            <h1 className="text-5xl italic uppercase text-main-color md:text-8xl tracking-widest" style={{ fontFamily: "Comforter Brush" }}>
                                Happiness
                            </h1>
                            <h2 className="text-3xl ml-4 lg:ml-24 font-txt-font uppercase text-main-color md:text-5xl font-thin">
                                Nails & Spa In Murrieta
                            </h2>
                        </div>
                        <div className=" flex space-y-4 text-sky-200  flex-col font-header-font">
                            <h1 className=" text-2xl lg:text-5xl">
                                24550 Village Walk, Suite D Murrieta, CA 92562
                            </h1>
                            <h2 className="text-2xl lg:text-5xl">
                                951.698.8897
                            </h2>
                        </div>
                    </div>
                    <div className="">
                        <button onClick={() => {
                            window.location.href = 'tel:9516988897';
                            }} className="hover:shadow-lg hover:-translate-y-2 hover:bg-main-color text-white ease-in-out delay-100 duration-150 mt-5 md:text-xl border-2 border-main-color text-lg py-3 px-12 group cursor-pointer md:tracking-wide">
                            <span className="">Book Now</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className=" pt-6 pb-8 lg:pt-24 lg:pb-24 space-y-4">
                <div className="lg:p-4 p-2 mx-auto max-w-screen-xl">
                    <div className=" grid gap-8 lg:gap-12 place-items-center lg:grid-cols-4">
                        <Fade>
                            <div className=" flex flex-col lg:space-y-8">
                                <img alt="da" src={img1} />
                                <div className=" pb-8"></div>
                                <img alt="da"  className=" ml-12" src={img2} />
                            </div>
                        </Fade>
                        <div className=" flex lg:col-span-2 space-y-4 lg:space-y-8 flex-col capitalize text-center">
                            <span style={{fontFamily: 'Redressed'}} className=" text-2xl text-black/40">About us</span>
                            <div className=" flex flex-col space-y-4">
                                <span className=" text-4xl font-header-font text-black">
                                    Revel in your innate charm with a novel
                                </span>
                                <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                    Outlook
                                </span>
                            </div>
                            <p className=" text-xl text-black/60 font-txt-font">
                                Discover elegance at Happiness Nail in Murrieta, CA, where 18 years of excellence await. 
                                Treat yourself to exquisite nail artistry in a serene atmosphere. 
                                From classic manicures to stunning designs, indulge in luxury with us today
                            </p>
                            <div className="">
                                <button onClick={() => {
                                    window.location.href = 'tel:9516988897';
                                }} className="hover:shadow-lg font-txt-font hover:-translate-y-2 hover:bg-main-color hover:text-white ease-in-out delay-100 duration-150 mt-5 md:text-xl border-2 border-main-color text-lg py-3 px-12 group cursor-pointer md:tracking-wide">
                                    <span className="">Book Now</span>
                                </button>
                            </div>
                        </div>
                        <Fade>
                            <div className=" flex flex-col lg:space-y-8">
                                <img alt="da" src={imgser3} />
                                <div className=" pb-8"></div>
                                <img alt="da" className=" ml-12" src={imgser4} />
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className="lg:grid hidden lg:grid-cols-2">
                <img alt="da"  className=" cursor-pointer h-[600px] w-full" src= {img3} />
                <img alt="da"  className=" h-[600px] cursor-pointer  w-full" src={img4} />
            </div>
            <div className=" p-5 pt-6 pb-8 lg:pt-24 lg:pb-24 bg-[#dae0e6]">
                <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                    <div className="flex flex-col text-center space-y-8">
                        <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-black/40">Our Services</span>
                        <div className=" flex flex-col space-y-4">
                            <span className=" text-4xl font-header-font text-black">
                                Embrace your unique allure with a fresh
                            </span>
                            <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                Perspective
                            </span>
                        </div>
                    </div>
                    <div className=" grid gap-12 lg:gap-8 place-items-center lg:grid-cols-3">
                        <Slide>
                            {ServicesData.map((s) => (
                                <div className=" cursor-pointer flex text-center justify-center items-center space-y-5 flex-col">
                                    <img className="ease-in-out delay-100 duration-150 transition-all hover:-translate-y-2" src={s.img} alt={s.img} />
                                    <div className=" flex flex-col space-y-5">
                                        <div className=" space-y-2">
                                            <div>
                                                <h2 style={{fontFamily: 'Redressed'}} className=" capitalize text-3xl">{s.name}</h2>
                                            </div>
                                            <div>
                                                <span className=" text-black/60 font-txt-font text-xl">
                                                    {s.desc}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="">
                                            <button onClick={() => {
                                                window.open('/Services' , '_blank');
                                            }} className="hover:shadow-lg font-txt-font hover:-translate-y-2 hover:bg-main-color hover:text-white ease-in-out delay-100 duration-150 md:text-xl border-2 border-main-color text-lg py-3 px-12 group cursor-pointer md:tracking-wide">
                                                <span className="">View More</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slide>
                    </div>
                </div>
            </div>
            <div className=" p-5 pt-6 pb-8  lg:pt-24 lg:pb-24 bg-white">
                <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                    <div className=" grid gap-8 lg:grid-cols-2">
                        <Fade>
                            <div className="flex flex-col space-y-8">
                                <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-black/40">Discover Excellence</span>
                                <div className=" flex flex-col space-y-4">
                                    <span className=" text-4xl font-header-font text-black">
                                        Expert pampering and luxurious
                                    </span>
                                    <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                        Treatments
                                    </span>
                                </div>
                                <span className=" text-lg text-black/80 font-txt-font">
                                    Select our salon for unparalleled pampering and beauty expertise. 
                                    Experience luxury services in a serene ambiance, leaving you refreshed and radiant with every visit
                                </span>
                                <div className=" font-txt-font">
                                    <ul className=" space-y-8">
                                        <li className=" flex flex-col">
                                            <div className=" flex space-x-2 text-2xl">
                                                <span style={{fontFamily: 'Calligraffitti'}} className="">01</span>
                                                <span className="">
                                                    Expertise and Precision
                                                </span>
                                            </div>
                                            <span className=" text-black/60 ml-8 text-lg font-thin">
                                                Experience top-notch nail care delivered by skilled technicians dedicated to perfection.
                                            </span>
                                        </li>
                                        <li className=" flex flex-col">
                                            <div className=" flex space-x-2 text-2xl">
                                                <span style={{fontFamily: 'Calligraffitti'}} className="">02</span>
                                                <span className="">
                                                    Luxurious Pampering
                                                </span>
                                            </div>
                                            <span className=" text-black/60 ml-8 text-lg font-thin">
                                                Indulge in a world of relaxation and luxury where every visit is a rejuvenating escape from the everyday hustle.                                        
                                            </span>
                                        </li>
                                        <li className=" flex flex-col">
                                            <div className=" flex space-x-2 text-2xl">
                                                <span style={{fontFamily: 'Calligraffitti'}} className="">03</span>
                                                <span className="">
                                                    Personalized Service
                                                </span>
                                            </div>
                                            <span className=" text-black/60 ml-8 text-lg font-thin">
                                                Enjoy tailor-made treatments in a serene ambiance, ensuring each client leaves feeling pampered and satisfied.                                        
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                        <div className=" lg:hidden flex">
                            <img alt="da" src={serimg} />
                        </div>
                        <div className="hidden lg:block lg:bg-bgser lg:w-full lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                        </div>
                    </div>
                </div>
            </div>
            <div className=" p-5 lg:pt-24 lg:pb-24 bg-[#dae0e6]">
                <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                    <div className="flex flex-col text-center space-y-8">
                        <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-black/40">Testimonials</span>
                        <div className=" flex flex-col space-y-4">
                            <span className=" text-4xl font-header-font text-black">
                               Reviews & Feedback From
                            </span>
                            <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                Client
                            </span>
                        </div>
                        <div>
                            <SwiperReview />
                        </div>
                    </div>
                </div>
            </div>
            <div className=" p-5 pt-6 pb-8  lg:pt-24 lg:pb-24 bg-white">
                <div className="mx-auto max-w-screen-xl">
                    <div className=" grid gap-8 lg:gap-12 lg:grid-cols-2">
                        <JackInTheBox>
                            <div className=" h-auto">
                                <img src={ser4} alt="da" />
                            </div>
                        </JackInTheBox>
                        <Fade>
                            <div className="flex lg:p-8 flex-col space-y-6">
                                <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-black/40">Pricing</span>
                                <div className=" flex flex-col space-y-4">
                                    <span className=" text-4xl font-header-font text-black">
                                        Transparent pricing, premium
                                    </span>
                                    <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                        Services
                                    </span>
                                </div>
                                <span className=" text-lg text-black/60 font-txt-font">
                                    Discover transparent pricing tailored to your beauty needs.
                                    Explore our competitive rates and indulge in premium services without breaking the bank.
                                </span>
                                {ServicesData.slice(0,5).map((s) => (
                                    s.services.slice(0,1).map((ser , index) => (
                                        <>                                    
                                            <div key={index} className=" text-2xl flex w-full justify-between">
                                                <div  style={{fontFamily: 'Calligraffitti'}} className=" flex space-y-2 flex-col">
                                                    <span>{ser.name}</span>
                                                </div>
                                                <span className="font-header-font">{ser.price}</span>
                                            </div>
                                            <hr></hr>
                                        </>
                                    ))
                                ))}
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <Slide>
                <div className=" p-5 pt-6 pb-8  lg:pt-24 lg:pb-24 bg-[#f2efef]">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" grid gap-8 lg:grid-cols-2">
                            <div className="flex flex-col space-y-8">
                                <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-black/40">Contact Us</span>
                                <div className=" flex flex-col space-y-4">
                                    <span className=" text-4xl font-header-font text-black">
                                        Reach out for personalized beauty
                                    </span>
                                    <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                        Solutions
                                    </span>
                                </div>
                                <span className=" text-lg text-black/80 font-txt-font">
                                    Get in touch effortlessly. Reach out today for personalized beauty 
                                    solutions and exceptional service.
                                </span>
                                <div className=" font-txt-font">
                                    <ul className="space-y-8 text-xl">
                                        <li>
                                            <a className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end" href="tel:9516988897">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-12 w-12 shrink-0 text-main-color"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                                    />
                                                </svg>
                                                <span className="flex-1 text-black">(951) 698-8897</span>
                                            </a>
                                        </li>
                                        <li className="flex items-start justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-12 w-12 shrink-0 text-main-color"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                                />
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                                />
                                            </svg>
                                            <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/cotJyykT8YmggX7u8" className="-mt-0.5 flex-1 not-italic text-black">
                                                24550 Village Walk Pl, Murrieta, CA 92562
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className=" lg:hidden flex">
                                <img alt="da" src={ser5} />
                            </div>
                            <div className="hidden lg:block lg:bg-bgser1 lg:w-full lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                            </div>
                        </div>
                    </div>
                </div>
            </Slide>
        </section>
    )
}