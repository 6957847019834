import React from "react";
import img1 from "../img/services/11.png";
import img2 from "../img/services/12.png";
import img3 from "../img/services/13.png";
import img4 from "../img/services/14.png";
import img5 from "../img/services/15.png";
import img6 from "../img/services/16.png";
import img7 from "../img/services/17.png";
import img8 from "../img/services/18.png";

const ServicesData = [
    {
        id: 1,
        name: 'Spa Pedicure',
        img: img2,
        services: [
            {
                name: 'Spa Pedicure',
                price: '$28',
                desc: 'Nail trim, shaping , cuticle grooming, lotion massage & polish'
            },
            {
                name: 'Classic Spa Pedicure',
                price: '$32',
                desc: 'Nail trim, shaping, cuticle grooming, lotion massage, sea scrub, hot towel , polish'
            },
            {
                name: 'Joy Spa Pedicure',
                price: '$37',
                desc: 'Nail magic: Trim, shape, & pamper with sea scrub, lotion massage, marine mask, salt exfoliation, hot towel, polish.'
            },
            {
                name: 'Joy Deluxe Pedicure',
                price: '$42',
                desc: 'Trim, shape, care for cuticles, apply mask and scrub, exfoliate, callus treatment, massage, hot towel, polish.'
            },
            {
                name: 'Hot Stone Pedicure',
                price: '$50',
                desc: 'Nail package: trim, shape, cuticle care, marine mask, sea scrub, salt glow, hot stone, callus treatment, 10-min massage, hot towel, polish'
            },
            {
                name: 'Paraffin Pedicure',
                price: '$50',
                desc: 'Complete nail care set: trimming, shaping, cuticle, paraffin, scrubs, mask, salt glow, massage, callus treatment, 10-min massage, hot towel, polish'
            },
            {
                name: 'Joyful Deluxe 2',
                price: '$62',
                desc: 'Nail care package: trim, shape, cuticle work, marine mask, hot stone, callus and paraffin treatments, 15-min massage, hot towel, polish.'
            },
            {
                name: 'Happiness Spa Pedi',
                price: '$75',
                desc: 'Nail care set: trim, shaping, cuticle care, marine mask, jelly, sea scrub, salt glow, hot stone, callus and paraffin treatment, 15-min massage, hot towel , polish'
            },
            {
                name: 'Add-On',
                desc: 'Gel Color: $15 , Take off Do Again: $20 , French Tip: $7'
            }
        ],
        desc: 'Indulge in ultimate relaxation with our rejuvenating spa pedicure service.'
    },
    {
        id: 2,
        name: 'Natural Nails Care',
        img: img1,
        services: [
            {
                name: 'Manicure',
                price: '$25',
                desc: 'Nail trim, shaping, cuticle grooming, lotion massage, polish'
            },
            {
                name: 'Happiness Deluxe Manicure',
                price: '$40',
                desc: 'Nail trim, shaping, cuticle grooming, oil treatment, paraffin treatment , hot towel, marine massage (10 min) & polish'
            },
            {
                name: 'Manicure Gel Color',
                price: '$38 up',
                desc: ''
            },
            {
                name: 'Manicure Gel French',
                price: '$42 up',
                desc: ''
            },
        ],
        desc: 'Transform your nails with precision and style through our expert manicure service.'
    },
    {
        id: 3,
        name: 'Kids Services',
        img: img3,
        services: [
            {
                name: "Kid's Mani/Pedi",
                price: '$30',
                desc: 'For 5 Years Old'
            },
            {
                name: "Kid's Mani/Pedi",
                price: '$35',
                desc: 'For 6-10 Years Old'
            }
        ],
        desc: 'Pamper your little ones with our specialized and gentle kids services.'
    },
    {
        id: 4,
        name: 'Waxing Service',
        img: img4,
        services: [
            {
                name: 'Eyebrows',
                price: '$12',
                desc: ''
            },
            {
                name: 'Eyebrows & Lips',
                price: '$17',
                desc: ''
            },
            {
                name: 'Chins',
                price: '$10 up',
                desc: ''
            },
            {
                name: 'Full Face',
                price: '$40 up',
                desc: ''
            },
            {
                name: 'Chest',
                price: '$20 up',
                desc: ''
            },
            {
                name: 'Bikini',
                price: '$35 up',
                desc: ''
            },
            {
                name: 'Brazilian',
                price: '$50 up',
                desc: ''
            }
        ],
        desc: 'Achieve smooth and silky skin with our professional waxing service.'
    },
    {
        id: 5,
        name: 'Eyelash & Facial',
        img: img5,
        services: [
            {
                name: 'Eyelash (Full Set)',
                price: '$120 up',
                desc: ''
            },
            {
                name: 'Eyelash (Fill)',
                price: '$50 up',
                desc: ''
            },
            {
                name: 'Happiness Facial',
                price: '$65 up',
                desc: ''
            }
        ],
        desc: 'Enhance your natural beauty with our luxurious eyelash and facial treatments.'
    },
    {
        id: 6,
        name: 'Fill & Full Set',
        img: img6,
        services: [
            {
                name: 'Regular Full Set W/Polish Gel',
                price: '$45 up',
                desc: ''
            },
            {
                name: 'Regular Fill W Color Gel',
                price: '$38 up',
                desc: ''
            },
            {
                name: 'Fill Pink & White Powder',
                price: '$45 up',
                desc: ''
            },
            {
                name: 'Full Set Ombre Pink/White',
                price: '$55 up',
                desc: ''
            },
            {
                name: 'Fill Ombre Pink / White',
                price: '$45 up',
                desc: ''
            },
            {
                name: 'Pink Fill Powder',
                price: '$38 up',
                desc: ''
            },
            {
                name: 'Gel-X',
                price: '$60 up',
                desc: ''
            },
            {
                name: 'Liquid Gel W/Gel-Color',
                price: '$50 up',
                desc: ''
            }
        ],
        desc: 'Experience flawless elegance with our fill and full set nail services.'
    },
    {
        id: 7,
        name: 'Additional Services',
        img: img7,
        desc: 'Elevate your nail art with our additional services, designed for that perfect finishing touch.',
        services: [
            {
                name: 'Nails Cut',
                price: '$5 up',
                desc: ''
            },
            {
                name: 'Nails Repair',
                price: '$5 up',
                desc: ''
            },
            {
                name: '2 Finger Design',
                price: '$8 up',
                desc: ''
            },
            {
                name: '2 Finger Design W/Rhinestone',
                price: '$12 up',
                desc: ''
            },
            {
                name: '1 Nails Full Rhinestone',
                price: '$12 up',
                desc: ''
            },
            {
                name: 'Custom Design',
                price: '$30 up',
                desc: ''
            },
            {
                name: 'Soak Off',
                price: '$15',
                desc: ''
            },
            {
                name: '2 Tip Toes',
                price: '$15',
                desc: ''
            },
            {
                name: 'Fullset Toes',
                price: '$40',
                desc: ''
            },
            {
                name: 'Fullset Toes W/Gel Color',
                price: '$65',
                desc: ''
            }
        ]
    },
    {
        id: 8,
        name: 'Custom Nails',
        services: [
            {
                name: '',
                price: 'Ask for more details'
            }
        ],
        img: img8,
        desc: 'Discover personalized beauty with our custom nails tailored to showcase your unique style.'
    }
];

export default ServicesData;